function bannerRanks () {
  const selectors = {}

  const initializeSelectors = () => {
    selectors.sortedBanners = document.querySelector('#banner_rank_ids')
    selectors.submitButton = document.querySelector('#submit-banners-ranks')
  }

  const initializeSortableListingTable = () => {
    $('.sortable-banners-list')
      .sortable({
        connectWith: '.sortable-banners-list',
        placeholder: 'sortable-placeholder',
        update: (event, ui) => {
          const childrens = event.target.querySelectorAll('.sortable-item')
          let i = 1
          const sortedItems = [...childrens].map((el) => {
            const lastColumn = el.querySelector('td:last-child')
            lastColumn.innerHTML = i++
            return Number(el.dataset.recordId)
          })
          $(selectors.sortedBanners).val(JSON.stringify(sortedItems))
          updateSubmitButtonState()
        }
      })
      .disableSelection()
  }
  const updateSubmitButtonState = () => {
    const sortedBannersValue = selectors.sortedBanners.value

    if (sortedBannersValue === '[]' || !sortedBannersValue) {
      selectors.submitButton.disabled = true
      selectors.submitButton.classList.add('disabled')
    } else {
      selectors.submitButton.disabled = false
      selectors.submitButton.classList.remove('disabled')
    }
  }

  // Initial check on page load
  if (window.location.pathname === '/home_screen_promotions/homescreen_banner') {
    initializeSelectors()
    initializeSortableListingTable()
    updateSubmitButtonState()
  }
}
export default bannerRanks
